import * as React from 'react';
import { IMyReaction, IReaction, itemHasReactions, itemsReactionsTotal, ReactionType } from "@@modules/community/data";
import { Entities } from '@inwink/entities/entities';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { AppTextLabel } from '@@services/i18nservice';

import './reactions.less';

export const ReactionIcon = (props: {
    reaction: ReactionType, i18nHelper: Entities.i18nHelper, filled?: boolean
}) => {
    const title = props.i18nHelper.translate("community.reaction." + props.reaction.toLowerCase());
    const display = reactionDisplay(props.reaction, props.filled);
    return display.text ? <span title={title} className='reactionicon icon'>{display.text}</span> : <i
        title={title} className={'reactionicon icon ' + display.icon}
    />;
};

function reactionDisplay(reaction: ReactionType, filled?: boolean) {
    switch (reaction) {
        case ReactionType.Like:
            return { icon: filled ? "inwink-thumbup-full" : "inwink-thumbup-outline" };
        case ReactionType.Love:
            return { icon: "inwink-heart" };
        case ReactionType.Lol:
            return { text: "\u{1F600}" };
        case ReactionType.Surprise:
            return { text: "\u{1F62E}" };
        case ReactionType.Sad:
            return { text: "\u{1F641}" };
    }
}

function renderReact(reaction: ReactionType, i18nHelper: Entities.i18nHelper,
    onReactionSelected: (arg: React.MouseEvent<HTMLButtonElement, MouseEvent>, reaction: ReactionType) => Promise<void>,
    selectedReaction?: ReactionType) {
    const title = i18nHelper.translate("community.reaction." + reaction.toLowerCase());
    return <AsyncButton title={title} className={'react ' + (selectedReaction == reaction ? "selected" : "")}
        onClick={(arg) => {
            arg.preventDefault();
            return onReactionSelected(arg, reaction);
        }}>
        <ReactionIcon reaction={reaction} i18nHelper={i18nHelper} />
    </AsyncButton>;
}

export const ReactionSelector = (props: {
    i18nHelper: Entities.i18nHelper,
    onReactionSelected: (arg: React.MouseEvent<HTMLButtonElement, MouseEvent>, reaction: ReactionType) => Promise<void>,
    selectedReaction?: ReactionType
}) => {
    return <div className='reactions-selector-container'>
        {renderReact(ReactionType.Like, props.i18nHelper, props.onReactionSelected, props.selectedReaction)}
        {renderReact(ReactionType.Love, props.i18nHelper, props.onReactionSelected, props.selectedReaction)}
        {renderReact(ReactionType.Lol, props.i18nHelper, props.onReactionSelected, props.selectedReaction)}
        {renderReact(ReactionType.Surprise, props.i18nHelper, props.onReactionSelected, props.selectedReaction)}
        {renderReact(ReactionType.Sad, props.i18nHelper, props.onReactionSelected, props.selectedReaction)}
    </div>;
};

export interface IReactionsCounterProps {
    item: { reactions?: IReaction; myReaction?: IMyReaction; };
    i18nHelper: Entities.i18nHelper;
    onReactionClicked: (arg: React.MouseEvent<any>, type?: ReactionType) => void;
    smartSort?: boolean;
    allwaysDisplayNb?: boolean;
    summurized?: boolean;
}

export class ReactionsCounter extends React.Component<IReactionsCounterProps, any> {
    renderReaction(reactionsElt: React.ReactNode[], type: ReactionType, nb: number) {
        const displayNb = !this.props.summurized && (nb > 1 || this.props.allwaysDisplayNb);
        const selected = this.props.item.myReaction?.likeType == type;
        const reaction = <span
            className={'reaction-container' + (!this.props.summurized ? ' clickable' : '')
                + (selected ? " selected" : "")}
            onClick={!this.props.summurized ? (arg) => {
                this.props.onReactionClicked(arg, type);
            } : null}>
            <ReactionIcon key={type} reaction={type} i18nHelper={this.props.i18nHelper} />
            {displayNb && <span className='nb'>{nb}</span>}
        </span >;
        if (this.props.smartSort && selected) {
            reactionsElt.unshift(reaction);
        } else {
            reactionsElt.push(reaction);
        }
    }

    render() {
        if (this.props.item.reactions != null && itemHasReactions(this.props.item)) {
            const reactions = this.props.item.reactions;
            const reactionsElt = [];
            if (reactions.likes > 0) {
                this.renderReaction(reactionsElt, ReactionType.Like, reactions.likes);
            }
            if (reactions.loves > 0) {
                this.renderReaction(reactionsElt, ReactionType.Love, reactions.loves);
            }
            if (reactions.lols > 0) {
                this.renderReaction(reactionsElt, ReactionType.Lol, reactions.lols);
            }
            if (reactions.surprises > 0) {
                this.renderReaction(reactionsElt, ReactionType.Surprise, reactions.surprises);
            }
            if (reactions.sads > 0) {
                this.renderReaction(reactionsElt, ReactionType.Sad, reactions.sads);
            }

            const content = <div className='reactions'>
                {reactionsElt}
            </div>;
            const count = itemsReactionsTotal(this.props.item);

            if (this.props.summurized) {
                return <div className='reactions-counter-container clickable'
                    onClick={(arg) => this.props.onReactionClicked(arg, null)}>
                    {content}
                    <AppTextLabel className='count bloc-accent'
                        i18n={`community.reaction.count${count == 1 ? ".single" : ""}`}
                        inject={{
                            nb: count
                        }} />
                </div>;
            } else {
                return <div className='reactions-counter-container'>
                    {content}
                </div>;
            }
        }

        return null;
    }
}
