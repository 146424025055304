import * as React from 'react';
import { ModalContent } from '@@components/modals';
import { AppTextLabel } from '@inwink/i18n';
import { IMember, ReactionType } from '@@modules/community/data';
import { Loader } from '@inwink/loader';
import { MemberInfoBubble } from '@@modules/community/components/memberinfo';
import { States } from '@@services/services';
import { Entities } from '@inwink/entities/entities';
import { ReactionIcon } from '.';

import "./memberlistmodal.less";

export interface IMemberListModalProps<T extends IMemberListItem> {
    onCompleted?: (res?: any) => void;
    getData: () => Promise<T[]>;
    i18nTitle: string;
    renderTrailing: (item: T) => React.ReactNode;
    noHeader?: boolean;
    className?: string;
    user?: States.IAppUserState;
}

export interface IMemberListItem {
    memberId: string;
    member?: IMember;
}

export function MemberListModal<T extends IMemberListItem>(props: IMemberListModalProps<T>) {
    const [items, setItems] = React.useState<T[]>(null);
    React.useEffect(() => {
        props.getData().then((res) => {
            if (res) {
                const currentMemberRes = res.filter((r) => r.memberId == props.user?.currentUser?.member?.id)[0];
                if (currentMemberRes) {
                    // on met le user courant en 1er
                    setItems([currentMemberRes, ...res.filter((r) => r.memberId != props.user?.currentUser?.member?.id)]);
                } else {
                    setItems(res);
                }
            }
        });
    }, []);

    return <ModalContent className={"memberlistmodalcontent " + (props.className ?? "")}>
        {props.noHeader ? null : <div className="header">
            <AppTextLabel component='h3' i18n={props.i18nTitle} />
            <button
                type="button"
                onClick={(arg) => {
                    arg.preventDefault();
                    arg.stopPropagation();
                    props.onCompleted();
                }}
                className="btnclose bloc-darkaccentbg"
            >
                <i className="inwink-dialog-cancel" />
            </button>
        </div>}
        <div className="members">
            {items == null ? <Loader /> : null}
            {items?.length ? items.map((item, idx) => {
                return <MemberItem key={idx}
                    member={item.member}
                    memberId={item.memberId}
                    trailing={props.renderTrailing(item)}
                />;
            }) : null}
            {items != null && items.length == 0 ? <AppTextLabel i18n='content.none' /> : null}
        </div>
    </ModalContent>;
}

interface IMemberItemProps {
    memberId: string;
    member: IMember;
    trailing: React.ReactNode;
}

const MemberItem = (props: IMemberItemProps) => {
    const member = props.member;

    const getCompanyInformations = () => {
        let jobInformations;

        if (member?.jobTitle && member?.company) {
            jobInformations = `${member.jobTitle}, ${member.company}`;
        } else {
            jobInformations = member?.jobTitle || member?.company;
        }

        if (jobInformations?.length > 50) {
            jobInformations = jobInformations.slice(0, 50) + '...';
        }
        return jobInformations;
    };

    const getFullName = () => {
        let fullName;
        fullName = `${member?.firstname?.trim()} ${member?.lastname?.trim()}`;

        if (fullName?.length > 40) {
            fullName = fullName.slice(0, 40) + '...';
        }
        return fullName;
    };

    const fullName = getFullName();
    let content;

    if (!member) {
        content = <div className="member-infos placeholder">
            <div className="member-bubble member-bubble-unknown bloc-lightborder bloc-accentbg inwink-account" />
            <div className="details">
                <AppTextLabel className='member-unknown' i18n='community.member.unknown' />
            </div>
        </div>;
    } else {
        content = <div className="member-infos">
            <MemberInfoBubble className="small" member={props.member} memberid={props.memberId} />
            <div className="details">
                <div className="fullname">
                    {fullName}
                </div>
                <div className="company-infos">
                    {getCompanyInformations()}
                </div>
            </div>
        </div>;
    }

    return <div className='member-infos-container'>
        {content}
        {props.trailing}
    </div>;
};

interface IMemberWithReaction {
    memberId: string;
    member?: IMember;
    likeType: ReactionType;
}

export interface IAllReactionsModalProps
    extends Omit<IMemberListModalProps<IMemberWithReaction>, 'renderTrailing' | 'i18nTitle'> {
    onCompleted?: () => void;
    displayReaction?: boolean;
    community: States.ICommunityState;
    i18nHelper: Entities.i18nHelper;
}

export const AllReactionsModal = (props: IAllReactionsModalProps) => {
    return <MemberListModal<IMemberWithReaction> {...props} className="allreactionsmodalcontent" getData={props.getData}
        i18nTitle='community.allreaction.all.title' renderTrailing={(like) => {
            return props.displayReaction ? <div className="react">
                <ReactionIcon reaction={ReactionType[like.likeType]} i18nHelper={props.i18nHelper} />
            </div> : null;
        }} />;
};

