import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n';
import { userMessageActions } from '@@services/usermessageactions/index';
import type { Entities } from '@inwink/entities/entities';

import './contextmenu.less';
import { AsyncButton } from '@inwink/buttons/asyncbutton';

export interface IContextMenuItem {
    icon?: string;
    title?: string;
    showIf?: (data) => boolean;
    callback?: (
        item: IContextMenuItem,
        i18nHelper: Entities.i18nHelper,
        data?,
        msgActions?: typeof userMessageActions,
        elt?: HTMLElement
    ) => Promise<any> | void;
    component?: any;
}

export interface IContextMenuProps {
    i18nHelper: Entities.i18nHelper;
    onCompleted?: (res?) => void;
    data?: any;
    items: IContextMenuItem[];
    userMessageActions: typeof userMessageActions;
    targetElement?: HTMLElement;
}

export class ContextMenu extends React.PureComponent<IContextMenuProps, any> {
    render() {
        const items = this.props.items.map((item) => {
            if (item.component) {
                return React.createElement(item.component, {
                    ...this.props,
                    key: item.icon + item.title,
                    item
                });
            }

            return <MenuItem
                key={item.icon + item.title}
                icon={item.icon}
                text={item.title}
                onClick={(arg) => {
                    arg.preventDefault();
                    const res = item.callback(
                        item, this.props.i18nHelper, this.props.data, this.props.userMessageActions, this.props.targetElement
                    );
                    if (!res || !res.then) {
                        this.props.onCompleted(res);
                        return;
                    }

                    return (res as any).then((promiseRes) => {
                        this.props.onCompleted(promiseRes);
                    });
                }}
            />;
        }).filter((c) => !!c);

        return <div className="contextmenu">
            {items}
        </div>;
    }
}

function MenuItem(props: { icon?: string, text: string, onClick: (arg:React.MouseEvent<any>) => void }) {
    return <AsyncButton className="menu-item" onClick={props.onClick}>
        <div className="menuicon">{props.icon ? <i className={(props.icon || "")} /> : null }</div>
        <AppTextLabel i18n={props.text} className="menutext" />
    </AsyncButton>;
}
