import * as React from 'react';
import { bindActionCreators } from 'redux';
import type { Entities } from '@inwink/entities/entities';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { userMessageActions } from '@@services/usermessageactions/index';
import { ContextMenu, IContextMenuItem } from './index';

import './moreactions.less';

export type { IContextMenuItem } from './index';

export interface IMoreActionsProps {
    i18nHelper?: Entities.i18nHelper;
    userMessageActions?: typeof userMessageActions;
    data: any;
    items: IContextMenuItem[];
    className?: string;
}

@connectwith(null, (dispatch) => {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
})
@withI18nHelper()
export class MoreActions extends React.PureComponent<IMoreActionsProps, any> {
    constructor(props: IMoreActionsProps) {
        super(props);

        this.state = {
            items: this.getItems(props)
        };
    }

    componentDidUpdate(prevprops: IMoreActionsProps) {
        if (prevprops.data !== this.props.data) {
            this.setState({ items: this.getItems(this.props) });
        }
    }

    getItems(props: IMoreActionsProps) {
        return props.items?.filter((item) => {
            return item.showIf
                ? item.showIf(props.data)
                : true;
        });
    }

    showMore = (arg: React.MouseEvent<any>) => {
        const target = arg.currentTarget;
        arg.preventDefault();
        this.props.userMessageActions.showMessage(
            this.props.i18nHelper,
            ContextMenu,
            { ...this.props, items: this.state.items, targetElement: target },
            target,
            null,
            null,
            "contextmenupopover"
        );
    };

    render() {
        if (!this.state.items?.length) {
            return null;
        }

        return <button title={this.props.i18nHelper.translate('actions.more')}
            type="button" className={"moreactions " + (this.props.className ?? '')} onClick={this.showMore}>
            <i className="inwink-more-v" />
        </button>;
    }
}
